.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #000;
  overflow: hidden;
}

.cardRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}