.figure {
  position: relative;

  button {
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }
  }
}