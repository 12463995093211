.text {
  height: 64px;
  width: 64px;
  font-size: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes example {
  from {
    font-size: 0;
  }
  to {
    font-size: 64px;
  }
}
