.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  gap: 10px;

  @media only screen and (max-width: 1400px) {
    flex-direction: column;
    align-items: center;
  }
}

.card {
  margin-top: 10px;
}