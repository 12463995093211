* {
  box-sizing: border-box;
}

body, html, #root {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: aquamarine;
}

.d-input {
  height: 40px;
  line-height: 40px;
  font-size: 32px;
  border: 1px solid blue;
  background-color: transparent;
  outline: 0;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.d-btn {
  height: 32px;
  font-size: 16px;
  border: 0;
  background-color: blue;
  color: white;
  outline: 0;
  border-radius: 4px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
}