.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

.emoji {
  font-size: 64px;
}